import { RefObject, useEffect, useState } from 'react';

// Add threshold to function parameters
function useOnScreen(
    ref: RefObject<HTMLElement>,
    rootMargin: string = '0px',
    threshold: number = 1
): boolean {
    const [isIntersecting, setIntersecting] = useState<boolean>(false);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => setIntersecting(entry?.isIntersecting ?? false),
            {
                rootMargin,
                threshold, // Add threshold to IntersectionObserver options
            }
        );

        if (ref.current) {
            observer.observe(ref.current);
        }

        return () => {
            observer.disconnect();
        };
    }, [ref, rootMargin, threshold]); // Add threshold to useEffect dependencies

    return isIntersecting;
}

export default useOnScreen;
