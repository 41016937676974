'use client';
import React, { useEffect, useState } from 'react';
import { AnimatedText } from '../components/AnimatedText/AnimatedText';

interface IHeroLabelSlider {}

export const HeroLabelSlider: React.FC<IHeroLabelSlider> = () => {
    const [current, setCurrent] = useState(0);

    useEffect(() => {
        // Rotate Headlines every X seconds
        const interval = setInterval(() => {
            setCurrent((c) => (c + 1) % 3);
        }, 3000);

        return () => clearInterval(interval);
    }, [current]);

    return (
        <span className="inline-flex w-full justify-center text-center">
            <span className="textColorAccentCB1 whitespace-nowrap">
                {current === 0 && <AnimatedText text={'web-to-print'} />}{' '}
            </span>
            <span className="textColorAccentCB1 whitespace-nowrap">
                {current === 1 && <AnimatedText text={'eCommerce'} />}{' '}
            </span>
            <span className="textColorAccentCB1">
                {current === 2 && <AnimatedText text={'building your own'} />}{' '}
            </span>
        </span>
    );
};
