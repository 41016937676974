import React, { useMemo } from 'react';

export interface ICircleBlur {
    color: 'cyan' | 'magenta' | 'yellow';
    className?: string;
}

export const CircleBlur: React.FC<ICircleBlur> = (props) => {
    const { color, className } = props;

    const bgColor: string = useMemo(() => {
        if (color) {
            if (color === 'cyan') {
                return 'bg-cyan-500 rotate-45 -top-16 left-20';
            } else if (color === 'magenta') {
                return 'bg-fuchsia-500 rotate-90 top-32 left-32';
            } else if (color === 'yellow') {
                return 'bg-yellow-500 top-0 -left-28';
            }
        }

        return 'bg-cyan-500 top-2 right-2';
    }, [color]);

    return (
        <div
            className={`circleBlur1 pointer-events-none absolute z-0 aspect-[1/1] h-[220%] select-none ${
                className ?? 'opacity-0'
            } ${bgColor}`}
        />
    );
};
