'use client';
import React from 'react';
import { motion } from 'framer-motion';
import { ButtonBar } from '../components/buttonBar/ButtonBar';
import { HeadlineTitle } from '../components/Typography/HeadlineTitle';
import { SublineTitle } from '../components/Typography/SublineTitle';
import { HeroLabelSlider } from './HeroLabelSlider';

interface IHeroCBIntro {}

export const HeroCBIntroPrint: React.FC<IHeroCBIntro> = () => {
    return (
        <section className="relative flex h-max w-full md:h-[600px]">
            <div className="absolute left-0 top-0 h-full w-full" />

            <motion.div
                initial={{ scale: 0, opacity: 0, y: '200px' }}
                animate={{ scale: 1, y: '0', opacity: 1 }}
                transition={{ duration: 0.4, ease: 'easeInOut' }}
                className="mx-auto h-full w-full max-w-7xl flex-col pt-8 md:pt-32"
            >
                <div className="relative mx-auto flex max-w-5xl flex-col items-center justify-center gap-2 px-12 text-center md:gap-4">
                    <div className="flex w-full md:min-h-[310px]">
                        <HeadlineTitle>
                            <span>cloudbar the smart platform for</span>
                            <HeroLabelSlider />
                        </HeadlineTitle>
                    </div>

                    <SublineTitle>
                        Take control of your business with CloudBar, the cloud-native platform
                        designed for flexibility and growth. With multi-client capabilities and easy
                        service integration, CloudBar streamlines your operations into an efficient
                        powerhouse. Personalize customer interactions and stay ahead of the
                        competition with tools to scale effortlessly.
                    </SublineTitle>

                    <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ duration: 0.2, ease: 'easeOut', delay: 0.5 }}
                        className="mt-8"
                    >
                        <ButtonBar />
                    </motion.div>
                </div>
            </motion.div>
        </section>
    );
};
