'use client';
import React, { PropsWithChildren } from 'react';
import { motion } from 'framer-motion';

export interface IPageTransition extends PropsWithChildren {}

const pageTransitionVariants = {
    hidden: { opacity: 0.7, x: 0, y: 10 },
    enter: { opacity: 1, x: 0, y: 0 },
    exit: { opacity: 0.7, x: 0, y: 20 },
};

export const ShopPageTransition: React.FC<IPageTransition> = (props) => {
    const { children } = props;

    return (
        <motion.div
            initial="hidden"
            animate="enter"
            exit="exit"
            variants={pageTransitionVariants}
            transition={{ type: 'linear', ease: 'easeInOut', duration: 0.3 }}
            style={{ position: 'relative', width: '100%' }}
        >
            {children}
        </motion.div>
    );
};
