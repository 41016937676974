import React, { PropsWithChildren } from 'react';

interface IHeadlineTitle extends PropsWithChildren {}

export const HeadlineTitle: React.FC<IHeadlineTitle> = (props) => {
    const { children } = props;

    return (
        <h1 className="w-full whitespace-normal text-[40px] font-black leading-tight tracking-wide text-white sm:text-[50px] md:text-[76px]">
            {children}
        </h1>
    );
};
