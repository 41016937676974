import React from 'react';
import { Button } from '../button/Button';
import { useWindowDimensions } from '../../hooks/useWindowDimensions';

interface IButtonBar {}

export const ButtonBar: React.FC<IButtonBar> = () => {
    const { isMobile } = useWindowDimensions();

    return (
        <section className="relative mx-auto flex w-full max-w-7xl flex-col items-center justify-center gap-2 md:flex-row md:gap-12">
            <Button href="/pricing" size={isMobile ? 'small' : 'large'}>
                Get Yours
            </Button>
            <Button href="/pricing" size={isMobile ? 'small' : 'large'} color="neutral">
                Build with cloudbar
            </Button>
        </section>
    );
};
