'use client';
import React, { PropsWithChildren, ReactElement, useMemo } from 'react';
import Link from 'next/link';
import Ripples from 'react-ripples';

export interface IButton extends PropsWithChildren {
    onClick?: () => void;
    href?: string;
    target?: string;
    additionalCss?: string;
    wrapperCss?: string;
    color?:
        | 'primary'
        | 'primary-light'
        | 'secondary'
        | 'danger'
        | 'success'
        | 'disabled'
        | 'neutral'
        | 'info'
        | 'admin-primary';
    iconEnd?: ReactElement<unknown>;
    iconStart?: ReactElement<unknown>;
    iconSpin?: boolean;
    disabled?: boolean;
    alignWithFormFields?: boolean;
    longBtn?: boolean;
    testId?: string;
    iconStartOpen?: ReactElement<unknown>;
    iconEndOpen?: ReactElement<unknown>;
    isResponsive?: boolean;
    responsiveShrink?: boolean;
    size?: 'small' | 'large';
}

export const Button: React.FC<IButton> = (props) => {
    const {
        children,
        additionalCss,
        wrapperCss,
        onClick,
        iconEnd,
        iconStart,
        href,
        target = '_self',
        disabled = false,
        iconSpin = false,
        testId,
        alignWithFormFields = false,
        iconEndOpen,
        iconStartOpen,
        responsiveShrink,
        size = 'small',
        color = 'primary',
    } = props;

    const colorStyles = useMemo((): string => {
        if (color === 'primary') {
            return 'text-white hover:text-neutral-900';
        }

        return 'text-neutral-400 hover:text-white';
    }, [color]);

    const borderStyles = useMemo((): string => {
        if (color === 'primary') {
            return 'buttonGradient rounded-full';
        }

        return 'buttonGradientNeutral rounded-full group-hover:buttonGradient';
    }, [color]);

    const iconSpinStyle = useMemo(() => {
        return iconSpin ? 'animate-spin' : '';
    }, [iconSpin]);

    const responsiveShrinkStyles = useMemo((): string => {
        if (responsiveShrink) return 'hidden lg:inline-flex';
        return 'inline';
    }, [responsiveShrink]);

    const textStyles = useMemo(() => {
        if (size === 'small') return 'text-sm';
        return 'text-md';
    }, [size]);

    const padding = useMemo(() => {
        if (size === 'small') {
            return 'px-4 py-2';
        }

        return 'px-8 py-5';
    }, [size]);

    const bgStyles = useMemo(() => {
        if (color === 'primary') {
            return 'bg-neutral-900 group-hover:bg-transparent';
        }

        if (color === 'neutral') {
            return 'bg-transparent group-hover:bg-neutral-900';
        }
    }, [color]);

    const buttonBody = (
        <>
            {(iconStart || iconStartOpen) && (
                <span className={`w-4 ${iconSpinStyle}`}>{iconStart ?? iconStartOpen}</span>
            )}
            {children && (
                <span
                    data-test-id={`${testId ?? ''}-label`}
                    className={`flex h-full w-full grow items-center justify-center whitespace-nowrap rounded-full transition-all ${bgStyles} ${padding} ${responsiveShrinkStyles} ${textStyles}`}
                >
                    <span className="transition-all group-hover:scale-[115%]">{children}</span>
                </span>
            )}

            {(iconEnd || iconEndOpen) && (
                <span className={`w-4 ${iconSpinStyle}`}>{iconEnd ?? iconEndOpen}</span>
            )}
        </>
    );

    const className = useMemo(() => {
        const baseCss = `relative group flex w-full flex-row items-center gap-2 rounded font-semibold tracking-wide ${borderStyles} ${colorStyles}`;
        const additionalCssOption = additionalCss ?? '';
        const cursorOption = disabled ? 'cursor-not-allowed' : 'cursor-pointer';
        const iconOption = iconStart || iconEnd ? 'justify-between' : 'justify-center';

        return `${baseCss} ${additionalCssOption} ${cursorOption} ${iconOption} px-4 py-3`;
    }, [borderStyles, colorStyles, additionalCss, disabled, iconStart, iconEnd]);

    const rippleAlignWithFields = useMemo(() => {
        if (alignWithFormFields) {
            return 'mt-[18px] h-[40px]';
        }

        return 'h-max';
    }, [alignWithFormFields]);

    return (
        <Ripples
            color={disabled ? 'rgba(0,0,0,0)' : 'rgba(255,255,255,0.2)'}
            during={500}
            className={`relative w-max rounded-full ${
                wrapperCss ?? ''
            } ${rippleAlignWithFields} overflow-hidden`}
            placeholder={undefined}
            onPointerEnterCapture={undefined}
            onPointerLeaveCapture={undefined}
        >
            <div className="relative flex w-full flex-col gap-0">
                {onClick && !href && (
                    <button
                        className={`button ${className}`}
                        onClick={() => {
                            setTimeout(() => {
                                if (onClick) onClick();
                            }, 150);
                        }}
                        role="button"
                        disabled={disabled}
                        aria-disabled={disabled}
                        data-test-id={`${testId ?? ''}-button`}
                    >
                        {buttonBody}
                    </button>
                )}

                {href && (
                    <Link
                        href={href}
                        onClick={onClick}
                        target={target}
                        className={`button ${className}`}
                        data-test-id={`${testId ?? ''}-button`}
                    >
                        {buttonBody}
                    </Link>
                )}
            </div>
        </Ripples>
    );
};
