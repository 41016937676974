'use client';
import React, { PropsWithChildren, useEffect, useMemo, useRef, useState } from 'react';
import { CircleBlur, ICircleBlur } from '../backgrounds/CircleBlur';
import useOnScreen from '../../hooks/useIntersectionObserver';
import { motion } from 'framer-motion';
import { useWindowDimensions } from '../../hooks/useWindowDimensions';

export interface IFeature extends PropsWithChildren {
    title: string;
    icon?: React.ReactNode;
    isOdd?: boolean;
}

export const Feature: React.FC<IFeature> = (props) => {
    const { title, children, isOdd } = props;

    const ref = useRef<HTMLDivElement | null>(null);

    const { isMobile } = useWindowDimensions();

    const isOnScreen = useOnScreen(ref, isMobile ? '0px' : '-150px', 0.5);

    const [show, setShow] = useState(false);

    const circleColor: ICircleBlur['color'] = useMemo(() => {
        // random color
        const colors = ['cyan', 'magenta', 'yellow'];
        return colors[Math.floor(Math.random() * colors.length)] as ICircleBlur['color'];
    }, []);

    useEffect(() => {
        if (isOnScreen && !show) {
            setShow(true);
        }
    }, [isOnScreen, show]);

    return (
        <div ref={ref} className="relative flex w-full">
            <motion.div
                initial={{ scale: 1.0, opacity: 0, x: isOdd ? '-50px' : '50px' }}
                animate={show ? { scale: 1, x: '0', opacity: 1 } : {}}
                transition={{ duration: 0.2, ease: 'easeOut' }}
                className="flex w-full flex-col gap-4 overflow-hidden rounded-xl border-2 border-border bg-neutral-800/50 p-10 backdrop-blur-2xl"
            >
                <h2 className="font-bold text-4xl text-neutral-50">{title}</h2>
                <span className="text-md font-medium text-neutral-50">{children}</span>
                <CircleBlur color={circleColor} className="opacity-50" />
            </motion.div>
        </div>
    );
};
