'use client';
import React, { PropsWithChildren } from 'react';
import { motion } from 'framer-motion';

interface ISublineTitle extends PropsWithChildren {}

export const SublineTitle: React.FC<ISublineTitle> = (props) => {
    const { children } = props;

    return (
        <motion.p
            initial={{ scale: 0.9, opacity: 0, y: '0' }}
            animate={{ scale: 1, y: '10px', opacity: 1 }}
            transition={{ duration: 0.2, ease: 'easeOut', delay: 0.5 }}
            className="mt-4 w-10/12 text-sm font-medium leading-relaxed text-neutral-200 md:text-xl"
        >
            {children}
        </motion.p>
    );
};
