'use client';
import React from 'react';
import { CircleBlur } from './CircleBlur';
import { motion } from 'framer-motion';

interface ICircleWrapper {
    top?: string;
    left?: string;
}

export const CircleWrapper: React.FC<ICircleWrapper> = (props) => {
    const { top, left } = props;
    return (
        <motion.div
            initial={{ scale: 0.75, opacity: 0, y: '0' }}
            animate={{ scale: 1, y: '10px', opacity: 1 }}
            transition={{ duration: 3.2, ease: 'easeOut', delay: 0.5 }}
            className="user-none pointer-events-none absolute h-52 max-h-full w-full select-none overflow-visible opacity-80"
            style={{
                top: top,
                left: left,
            }}
        >
            <CircleBlur color="cyan" />
            <CircleBlur color="yellow" />
            <CircleBlur color="magenta" />
        </motion.div>
    );
};
