import React, { useEffect, useRef, useState } from 'react';

interface IAnimatedText {
    text: string;
}

export const AnimatedText: React.FC<IAnimatedText> = (props) => {
    const { text } = props;

    const [textCurrent, setTextCurrent] = useState<{ count: number; current: string }>({
        count: 0,
        current: '',
    });

    const timer = useRef<NodeJS.Timer>();

    const started = useRef(false);

    useEffect(() => {
        if (textCurrent.count === 0 && !timer.current && !started.current) {
            started.current = true;
            setTimeout(() => {
                timer.current = setInterval(() => {
                    setTextCurrent((current) => {
                        if (text === current.current) {
                            clearInterval(timer.current as unknown as number);
                        }
                        return {
                            count: current.count + 1,
                            current: text.substring(0, current.count) ?? '',
                        };
                    });
                }, 80);
            }, 100);
        }
    }, [text, textCurrent, textCurrent.count]);

    return (
        <>
            {textCurrent.current.length === 0 && ' '}
            {textCurrent.current.length > 0 && textCurrent.current}
        </>
    );
};
