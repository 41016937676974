import { useEffect, useMemo, useState } from 'react';

export const useWindowDimensions = () => {
    // Initialize state with undefined width/height so server and client renders match
    // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
    const [windowSize, setWindowSize] = useState<{
        width: number | undefined;
        height: number | undefined;
    }>({
        width: undefined,
        height: undefined,
    });
    useEffect(() => {
        // Handler to call on window resize
        function handleResize() {
            // Set window width/height to state
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        }
        // Add event listener
        window.addEventListener('resize', handleResize);
        // Call handler right away so state gets updated with initial window size
        handleResize();
        // Remove event listener on cleanup
        return () => window.removeEventListener('resize', handleResize);
    }, []); // Empty array ensures that effect is only run on mount

    const isMobile = useMemo((): boolean | null => {
        if (!windowSize.width) {
            return null;
        }

        if (windowSize.width) return windowSize.width <= 1024;

        return false;
    }, [windowSize.width]);

    const isDesktop = useMemo((): boolean | null => {
        if (!windowSize.width) {
            return null;
        }

        if (windowSize.width) return windowSize.width > 1024;

        return false;
    }, [windowSize.width]);

    return { windowSize, isMobile, isDesktop };
};
